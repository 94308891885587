const Shimmer = ()=>{
    return(
        <div className="shimmer-container">
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            <div className="shimmer-cards">
                <div className="image-space"></div>
                <div className="heading-space"></div>
                <div className="info-space"></div>
            </div>
            
        </div>
    )
};

export default Shimmer;